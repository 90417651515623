export const operationFragment = gql`
    fragment operationFragment on OperationInfo {
        messages {
            code
            field
            kind
            message
        }
    }
`;
